import { createWeb3Modal, defaultConfig } from '@web3modal/ethers5/react'
const projectId = process.env.REACT_APP_PROJECT_ID;

export default function Connect() {
      const mainnet = {
        chainId: 56,
        name: 'Binance Smart Chain',
        currency: 'BNB',
        explorerUrl: 'https://bscscan.com/',
        rpcUrl: 'https://bsc-dataseed.binance.org/'
      }
      
      const metadata = {
        name: 'Unity Crowd',
        description: 'Unity Crowd is a 2x2 matrix-based crowdfunding platform built on Binance Smart Chain (BSC), designed to facilitate secure and efficient fund transfers and financial growth.',
        url: process.env.REACT_APP_DOMAIN_REF, 
        icons: [`${process.env.REACT_APP_DOMAIN_REF}/logo.png`]
      }
      
      const ethersConfig = defaultConfig({
        metadata,
        /*Optional*/
        enableEIP6963: true,
        enableInjected: true,
        enableCoinbase: true,
        rpcUrl: '...', // used for the Coinbase SDK
        defaultChainId: 56, // used for the Coinbase SDK
      })

      createWeb3Modal({
        ethersConfig,
        chains: [mainnet],
        projectId,
        tokens: {
          56: {
            address: '0x55d398326f99059ff775485246999027b3197955',
            image: 'https://tether.to/'
          }
        },
      })

      return (
        <w3m-button label='connect' balance={'show'} size={'md'}  />
      )
}

